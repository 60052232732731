<template>
  <div class="inspection">
    <div class="header">
      <img
        class="logo"
        height="35%"
        :src="require('@/assets/imgs/logo.png')"
      >
    </div>
    <Lang @changeLang="changeLang" />

    <FuFormMin
      v-if="showFuFormMin"
      ref="inspectionForm"
      v-model="formData"
      :schema="formJSON"
      :lang="lang"
    />

    <div class="button-group">
      <Button
        v-if="hasFinish"
        class="next-btn"
        type="primary"
        color="#d9000b"
        block
        plain
        @click="backTo"
      >
        {{ $t('claim.back') }}
      </Button>
      <Button
        v-if="!hasFinish"
        class="next-btn"
        type="primary"
        color="#d9000b"
        block
        @click="onSubmit('Draft')"
      >
        {{ $t('claim.Draft') }}
      </Button>
      <Button
        v-if="!hasFinish"
        class="next-btn"
        type="primary"
        color="#d9000b"
        block
        @click="onSubmit('Submit')"
      >
        {{ $t('submit') }}
      </Button>
    </div>

    <div v-if="showDraftSuccess" class="overlay">
      <img :src="img" class="success-image">
      <div class="success-title">{{ $t('claim.Thankyou') }}</div>
      <div class="success-message">{{ $t('claim.InspectionsAreSavedText') }} <p style="text-decoration: underline;" @click="() => {showDraftSuccess = false}">{{ $t('claim.ContinueToFinishText') }}</p> </div>
    </div>
  </div>
</template>

<script>
import { Button, Dialog, Notify } from 'vant'
import FuFormMin from '@/components/FuFormMin'
import { toastLoading } from '@/utils/index'
import { getDetail, saveForDraft, saveForSubmit } from './api'
import img from '@/assets/imgs/completedIcon.png'
import Dao from '@/utils/dao'
import CryptoJS from 'crypto-js'
import Lang from '@/views/inspectionCustomer/component/lang/index.vue'

export default {
  name: 'Inspection',
  components: {
    FuFormMin,
    Button,
    Lang
  },
  data() {
    return {
      lang: this.$store.state.base.lang,
      loading: true,
      showFuFormMin: false,
      type: '',
      formData: {},
      formJSON: {},
      checkSchema: {},
      inspectionInfo: {},
      img: img,
      hasFinish: false,
      showDraftSuccess: false,
      routeQuery: {},
      hasInit: false
    }
  },
  activated() {
    this.showDraftSuccess = false
    this.initParams()
    this.init()
  },
  mounted() {
    // this.initParams()
    // this.init()

    // const secretKey = '489E7BA7228D49589364C5C9EB54268E'
    // const cipherText = 'LwdNL1zOCoyMec2BcXsNbwMDKHCspGSDkdj61+4+il6rLYs9uAVWejIPa7LZfiUq'
    // const plainText = decryptByAES(cipherText, secretKey)
    // console.log(`解密后的内容为：${plainText}`)

    // console.log('----')
    // console.log(CryptoJS.AES.decrypt('LwdNL1zOCoyMec2BcXsNbwMDKHCspGSDkdj61+4+il6rLYs9uAVWejIPa7LZfiUq', '489E7BA7228D49589364C5C9EB54268E').toString(CryptoJS.enc.Utf8))
    // this.init()
  },
  methods: {
    initParams() {
      const { formNo, formTemplateCode, tenantId, reportUid, registerUid, tempVisitToken, applicationStatus } = this.$route.query
      this.routeQuery = {
        applicationStatus: applicationStatus,
        formNo: formNo,
        formTemplateCode: formTemplateCode,
        tenantId: tenantId,
        reportUid: this.decryptByAES(reportUid),
        registerUid: this.decryptByAES(registerUid),
        tempVisitToken: this.decryptByAES(tempVisitToken)
      }
      // console.log(this.routeQuery)
    },
    decryptByAES(cipherText, keyInBase64Str = '489E7BA7228D49589364C5C9EB54268E') {
      const key = CryptoJS.enc.Base64.parse(keyInBase64Str)
      // 返回的是一个Word Array Object，其实就是Java里的字节数组
      const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })

      return decrypted.toString(CryptoJS.enc.Utf8)
    },
    async init() {
      this.loading = toastLoading()
      const { formNo, formTemplateCode, tenantId, tempVisitToken, registerUid, reportUid, applicationStatus } = this.routeQuery
      Dao.set('claimToken', tempVisitToken)
      const params = { formNo, formTemplateCode, tenantId, registerUid, reportUid }
      const res = await getDetail(params)
      // console.log(JSON.parse(res.formData))
      this.formJSON = JSON.parse(JSON.parse(res.formSchema).displaySchema)
      this.formData = res.formData ? JSON.parse(res.formData) : {}
      this.showFuFormMin = true
      this.$nextTick(() => {
        this.loading = null
      })

      if (applicationStatus == 2) {
        this.hasFinish = true
      }
      this.hasInit = true
    },
    // 提交数据
    onSubmit(type) {
      if (type == 'Draft') {
        this.type = type
        this.handleSubmit()
        return
      }
      this.$refs.inspectionForm.validate().then(res => {
        this.type = type
        this.handleSubmit()
      }).catch(error => {
        console.log('----fail----')
        console.log(error)
      })
    },

    // 提交操作
    handleSubmit() {
      Dialog.confirm({
        message: this.getDialogMessage(),
        theme: 'round-button',
        confirmButtonText: this.$t('yes'),
        // confirmButtonColor: '#C82A21',
        showCancelButton: true,
        cancelButtonText: this.$t('no'),
        // cancelButtonColor: '#fff',
        beforeClose: this.beforeClose,
        className: 'inspection-dialog'
      })
    },
    getDialogMessage() {
      return `
        <i class="van-icon van-icon-warning-o" style="margin-bottom: 25px; color: #C82A21; font-size: 65px;"></i>
        <p class="tit" style="font-weight: 900; font-size: 14px; color: #C82A21;">${this.$t(`confirm${this.type}`)}</p>
      `
    },
    async beforeClose(action, done) {
      // 取消 这个设计稿是把确认放在左边
      // 所以组件的confirm 相当于取消
      console.log(action, done)
      if (action !== 'confirm') {
        this.type = ''
        done()
        return
      }
      const { formNo, tenantId, registerUid, reportUid } = this.routeQuery
      const params = {
        formNo,
        formDataJson: JSON.stringify(this.formData),
        reportUid,
        tenantId,
        registerUid
      }
      if (this.type === 'Draft') {
        const res = await saveForDraft(params)
        if (res.claimFormId) {
          this.showDraftSuccess = true
          done()
        }
      }
      if (this.type === 'Submit') {
        const res = await saveForSubmit(params)
        if (res.claimFormId) {
          done()
          Notify({ type: 'success', message: 'Success' })
          history.go(-1)
        }
      }
      return
    },
    backTo() {
      history.go(-1)
    },
    changeLang(val) {
      if (this.hasInit) {
        this.lang = val
        this.init()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 40px;
  background: #fff;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  .van-button {
    // background: #C82A21;
    box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    .van-button__text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      // color: #FFFFFF;
    }
  }

  .van-button + .van-button {
    margin-left: 10px;
  }
}
.inspection {
  padding-bottom: 50px;
  background-color: #fbfbfb;

  .reject-reason {
    font-size: 15px;
    padding: 20px;
    margin: 10px 20px 0px 20px;
    background-color: #FFB8B8;
    color: #CC0404;
    border-radius: 10px;
  }

  .otp-desc {
    background: #D9D9D9;
    font-size: 12px;
    margin: 15px;
    padding: 10px;
  }

}
</style>

<style lang="scss">
.inspection-dialog {
  .van-dialog__footer {
    justify-content: space-between;
  }
  .van-dialog__message {
    white-space: pre-line;
  }
  .van-dialog__cancel,
  .van-dialog__confirm {
    width: 130px;
    border: 1.5px solid #C82A21;
    box-shadow: 0px .35px 1.4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    background: none;
  }
  .van-dialog__cancel {
    background-color: #FFFFFF;
    color: #C82A21;
  }
  .van-dialog__confirm {
    margin-left: 10px;
    background-color: #C82A21;
  }
}
.button-group {
  padding: 20px;
  display: flex;
  justify-content: space-around;
}
.next-btn {
  margin: 20px 5px;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  z-index: 999;
  .success-image {
    border: none;
    width: 150px;
    max-width: 100%;
    vertical-align: middle;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .success-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    color: #49e197;
    top: 50%;
    width: 100%;
  }
  .success-message {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    color: #000;
    bottom: 10%;
    width: 100%;
  }
}
.header {
  padding: 0 20px;
  height: 100px;
  line-height: 75px;
  background-color: #D90109;
  font-size: 0;
  text-align: center;
}
</style>
